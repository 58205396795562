<template>
  <div>
    <v-dialog data-cy="dialog" v-model="showDialog" max-width="650">
      <v-card>
        <v-toolbar flat color="blueGreyLight" class="text">
          <v-tabs v-model="tab" v-if="hasTabs">
            <v-tab>Job</v-tab>
            <v-tab>Input Structures</v-tab>
            <v-tab>Job Parameters</v-tab>
          </v-tabs>
          <v-toolbar-title v-else>
            {{ infoTitle }}{{ toolbarTitle }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <div class="mr-1"><CopyToClipboard v-bind:copyData="copyData" /></div>
        </v-toolbar>

        <SuccessAlert
          v-if="showAlert"
          v-bind:type="alertType"
          v-bind:message="alertMessage"
        />
        <v-tabs-items v-model="tab" v-if="hasTabs">
          <v-tab-item>
            <v-card flat>
              <Header :headerTitle="'Job:'"> </Header>
              <v-card-text>
                <pre data-cy="job_config">{{ job }}</pre>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <Header :headerTitle="'Structures:'"> </Header>
              <v-card-text>
                <pre data-cy="structure_config">{{ structures }}</pre>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <Header :headerTitle="'Job Parameters:'"> </Header>
              <v-card-text>
                <pre data-cy="param_config">{{ jobParam }}</pre>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-card-text v-else>
          <pre data-cy="structure_config">{{ structure }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const SuccessAlert = () => import('./SuccessAlert.vue')
const CopyToClipboard = () => import('./CopyToClipboard.vue')
const Header = () => import('./Header.vue')
import { mapState } from 'vuex'

export default {
  components: {
    CopyToClipboard,
    SuccessAlert,
    Header,
  },

  data() {
    return {
      showDialog: false,
      showName: process.env.VUE_APP_SUBDOMAIN !== '',
      items: ['Job', 'Input Structures', 'Job Parameters'],
      tab: 0,
    }
  },

  props: {
    user: Object,
    job: Object,
    structures: Array,
    structure: Object,
    jobParam: Object,
    hasTabs: Boolean,
    infoTitle: String,
  },

  methods: {
    openDialog() {
      this.showDialog = true
    },
  },

  computed: {
    ...mapState('backend', {
      showAlert: (state) => state.showAlert,
      alertType: (state) => state.alertType,
    }),

    alertMessage() {
      if (this.alertType === 'success') {
        return 'Copied successfully!'
      } else {
        return 'Copying failed!'
      }
    },

    toolbarTitle() {
      if (this.structure) {
        return this.structure.label
      } else {
        return ''
      }
    },

    copyData() {
      if (!this.hasTabs) {
        return this.structure
      } else if (this.tab === 0) {
        return this.job
      } else if (this.tab === 1) {
        return this.structures
      } else if (this.tab === 2) {
        return this.jobParam
      } else {
        return {}
      }
    },
  },
}
</script>

<style scoped></style>
